import store from '@/store'

function genCard(vcard) {
  console.log(vcard)
  let card = `
  {
  "altText": "SlashCard電子名片",
  "type": "flex",
  "contents": {
    "type": "bubble",
    "size": "giga",
    "body": {
      "type": "box",
      "layout": "vertical",
      "contents": [
        {
          "type": "image",
          "url": "https://card.h888.fun/images/background/jci.png?v=10",
          "size": "full",
          "aspectMode": "cover",
          "aspectRatio": "544:308",
          "gravity": "top"
        },
        {
          "type": "box",
          "layout": "horizontal",
          "contents": [
            {
              "type": "box",
              "layout": "vertical",
              "contents": [
                {
                  "type": "box",
                  "layout": "vertical",
                  "contents": [
                    {
                      "type": "image",
                      "url": "${vcard.avatar}"
                    }
                  ],
                  "width": "80%",
                  "offsetTop": "10%",
                  "position": "absolute"
                },
                {
                  "type": "box",
                  "layout": "vertical",
                  "contents": [
                    {
                      "type": "box",
                      "layout": "vertical",
                      "contents": [
                        {
                          "type": "text",
                          "text": "加入好友",
                          "color": "#FFFFFF",
                          "size": "xs",
                          "align": "center"
                        }
                      ],
                      "backgroundColor": "#66B3FF",
                      "cornerRadius": "5px",
                      "paddingTop": "5px",
                      "paddingBottom": "5px",
                      "margin": "md",
                      "paddingStart": "5px",
                      "paddingEnd": "5px",
                      "action": {
                        "type": "uri",
                        "label": "action",
                        "uri": "https://line.naver.jp/ti/p/~${vcard.line}"
                      }    
                    },
                    {
                      "type": "box",
                      "layout": "vertical",
                      "contents": [
                        {
                          "type": "text",
                          "text": "分享名片",
                          "color": "#FFFFFF",
                          "size": "xs"
                        }
                      ],
                      "backgroundColor": "#66B3FF",
                      "cornerRadius": "5px",
                      "paddingTop": "5px",
                      "paddingBottom": "5px",
                      "margin": "md",
                      "paddingStart": "5px",
                      "paddingEnd": "5px",
                      "action": {
                        "type": "uri",
                        "label": "action",
                        "uri": "${process.env.VUE_APP_SEND_URL}/?userid=${vcard.user_id}&cardid=5"
                      }    
                    }
                  ],
                  "position": "absolute",
                  "offsetBottom": "10%"
                }
              ],
              "width": "23%",
              "alignItems": "center",
              "height": "100%"
            },
            {
              "type": "box",
              "layout": "vertical",
              "contents": [
                {
                  "type": "box",
                  "layout": "vertical",
                  "contents": [
                    {
                      "type": "text",
                      "text": " ${vcard.company}",
                      "color": "#0066cc",
                      "align": "end",
                      "weight": "regular",
                      "size": "md"
                    },
                    {
                      "type": "text",
                      "text": " ${vcard.tel} ${vcard.address}",
                      "align": "end",
                      "size": "xxs"
                    },
                    {
                      "type": "text",
                      "text": " ${vcard.email}",
                      "align": "end",
                      "size": "xxs"
                    }
                  ],
                  "width": "100%",
                  "offsetTop": "65%",
                  "offsetEnd": "5%"
                },
                {
                  "type": "box",
                  "layout": "vertical",
                  "contents": [
                    {
                      "type": "text",
                      "text": " ${vcard.name}",
                      "align": "end",
                      "color": "#66B3FF"
                    },
                    {
                      "type": "text",
                      "text": " ${vcard.title}",
                      "size": "sm",
                      "align": "end"
                    },
                    {
                      "type": "text",
                      "text": " ${vcard.phone}",
                      "size": "sm",
                      "align": "end"
                    }
                  ],
                  "width": "45%",
                  "offsetTop": "30%",
                  "position": "absolute",
                  "offsetEnd": "5%"
                }
              ],
              "width": "77%"
            }
          ],
          "position": "absolute",
          "width": "100%",
          "height": "100%"
        }
      ],
      "position": "relative",
      "spacing": "none",
      "margin": "none",
      "paddingAll": "none"
    }
  }}
  `

  return { card: card}
}

export default genCard ;
