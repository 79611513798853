function genCard(vcard) {
  // 名片預覽
  let vcardLink;
  if (vcard.url.trim().length > 0) {
    if (vcard.nc_func.indexOf("nourl") !== -1) {
      vcardLink = `{
        "type": "box",
        "layout": "baseline",
        "contents": [
          {
            "type": "icon",
            "url": "https://demo.zltest.com.tw/line/icon02.png",
            "offsetTop": "3px"
          },
          {
            "type": "text",
            "text": "${vcard.url}",
            "color": "#333334",
            "size": "sm",
            "weight": "bold"
          }
        ],
        "spacing": "lg",
        "margin": "sm"
      },
      `;
    } else {
      vcardLink = `
      {
        "type": "box",
        "layout": "baseline",
        "contents": [
          {
            "type": "icon",
            "url": "https://demo.zltest.com.tw/line/icon02.png",
            "offsetTop": "3px"
          },
          {
            "type": "text",
            "text": "${vcard.url}",
            "color": "#333334",
            "size": "sm",
            "weight": "bold",
            "action": {
              "type": "uri",
              "label": "action",
              "uri": "${vcard.url}"
            }
          }
        ],
        "spacing": "lg",
        "margin": "sm"
      },
      `;
    }
  } else {
    vcardLink = "";
  }

  let vcardAddr;
  if (vcard.address.trim().length > 0) {
    vcardAddr = `
      {
        "type": "box",
        "layout": "baseline",
        "contents": [
          {
            "type": "icon",
            "url": "https://demo.zltest.com.tw/line/icon04.png",
            "offsetTop": "3px"
          },
          {
            "type": "text",
            "text": "${vcard.address}",
            "color": "#333334",
            "size": "sm",
            "weight": "bold",
            "action": {
              "type": "uri",
              "label": "action",
              "uri": "https://www.google.com.tw/maps/place/${encodeURIComponent(
                vcard.address
              )}"
            },
            "wrap": true
          }
        ],
        "spacing": "lg",
        "margin": "sm"
      },      
      `;
  } else {
    vcardAddr = "";
  }

  let vcardTel;

  if (vcard.tel.trim().length > 0) {
    vcardTel = `
    {
      "type": "box",
      "layout": "baseline",
      "contents": [
        {
          "type": "icon",
          "url": "https://demo.zltest.com.tw/line/icon01.png",
          "offsetTop": "3px"
        },
        {
          "type": "text",
          "text": "${vcard.tel}",
          "color": "#333334",
          "size": "sm",
          "weight": "bold",
          "action": {
            "type": "uri",
            "label": "action",
            "uri": "tel:${vcard.tel}"
          }
        }
      ],
      "spacing": "lg",
      "margin": "sm"
    },
    `;
  } else {
    vcardTel = "";
  }

  let vcardPhone;

  if (vcard.phone.trim().length > 0) {
    vcardPhone = `
    {
      "type": "box",
      "layout": "baseline",
      "contents": [
        {
          "type": "icon",
          "url": "https://demo.zltest.com.tw/line/icon01.png",
          "offsetTop": "3px"
        },
        {
          "type": "text",
          "text": "${vcard.phone}",
          "color": "#333334",
          "size": "sm",
          "weight": "bold",
          "action": {
            "type": "uri",
            "label": "action",
            "uri": "tel:${vcard.phone}"
          }
        }
      ],
      "spacing": "lg",
      "margin": "sm"
    },
    `;
  } else {
    vcardPhone = "";
  }

  let vcardAvatar;

  if (vcard.avatar.trim().length > 0) {
    vcardAvatar = `
    {
      "type": "image",
      "url": "${vcard.avatar}",
      "size": "xs",
      "action": {
        "type": "uri",
        "label": "action",
        "uri": "${vcard.nfcurl}&cardid=1"
      }    
    },
    `;
  } else {
    vcardAvatar = "";
  }

  let vcardEmail;

  if (vcard.email.trim().length > 0) {
    vcardEmail = `
    {
      "type": "box",
      "layout": "baseline",
      "contents": [
        {
          "type": "icon",
          "url": "https://demo.zltest.com.tw/line/icon03.png",
          "offsetTop": "3px"
        },
        {
          "type": "text",
          "text": "${vcard.email}",
          "color": "#333334",
          "size": "sm",
          "weight": "bold",
          "action": {
            "type": "uri",
            "label": "action",
            "uri": "mailto:${vcard.email}"
          }
        }
      ],
      "spacing": "lg",
      "margin": "sm"
    },
    `;
  } else {
    vcardEmail = "";
  }

  // 名片發送

  let card = `
    {
      "altText": "SlashCard電子名片",
      "type": "flex",
      "contents": 
      {
        "type": "bubble",
        "size": "giga",
        "body": {
          "type": "box",
          "layout": "vertical",
          "contents": [
            {
              "type": "image",
              "url": "https://demo.zltest.com.tw/line/bg02.jpg",
              "size": "full",
              "aspectMode": "cover",
              "aspectRatio": "4:3",
              "gravity": "top",
              "action": {
                "type": "uri",
                "label": "action",
                "uri": "${vcard.nfcurl}&cardid=1"
              }    
            },
            {
              "type": "box",
              "layout": "vertical",
              "contents": [
                ${vcardAvatar}
                {
                  "type": "text",
                  "text": "${vcard.company}",
                  "color": "#ffffff",
                  "size": "md",
                  "weight": "bold",
                  "wrap": true,
                  "align": "center",
                  "margin": "sm",
                  "action": {
                    "type": "uri",
                    "label": "action",
                    "uri": "${vcard.nfcurl}&cardid=1"
                  }        
                }
              ],
              "position": "absolute",
              "offsetTop": "13%",
              "offsetStart": "3%",
              "width": "26%"
            },
            {
              "type": "box",
              "layout": "vertical",
              "contents": [
                {
                  "type": "box",
                  "layout": "vertical",
                  "contents": [
                    {
                      "type": "text",
                      "text": "加入好友",
                      "align": "center",
                      "color": "#ffffff",
                      "weight": "bold",
                      "margin": "xs"
                    }
                  ],
                  "backgroundColor": "#06c755",
                  "width": "100%",
                  "cornerRadius": "5px",
                  "action": {
                    "type": "uri",
                    "label": "action",
                    "uri": "https://line.naver.jp/ti/p/~${vcard.line}"
                  },
                  "paddingTop": "5px",
                  "paddingBottom": "5px"
                },
                {
                  "type": "box",
                  "layout": "vertical",
                  "contents": [
                    {
                      "type": "text",
                      "text": "分享名片",
                      "align": "center",
                      "color": "#ffffff",
                      "weight": "bold",
                      "margin": "xs"
                    }
                  ],
                  "backgroundColor": "#ffcc5b",
                  "width": "100%",
                  "cornerRadius": "5px",
                  "margin": "md",
                  "paddingTop": "5px",
                  "paddingBottom": "5px",
                  "action": {
                    "type": "uri",
                    "label": "action",
                    "uri": "${process.env.VUE_APP_SEND_URL}/?userid=${vcard.user_id}&cardid=1"
                  }
                }
              ],
              "position": "absolute",
              "offsetStart": "4%",
              "width": "25%",
              "offsetBottom": "5%"
            },
            {
              "type": "box",
              "layout": "vertical",
              "contents": [
                {
                  "type": "box",
                  "layout": "baseline",
                  "contents": [
                    {
                      "type": "text",
                      "text": "${vcard.title}",
                      "color": "#333334",
                      "size": "md",
                      "weight": "bold"
                    }
                  ],
                  "spacing": "lg"
                },
                {
                  "type": "box",
                  "layout": "vertical",
                  "contents": [
                    {
                      "type": "text",
                      "text": "${vcard.name}",
                      "size": "xxl",
                      "color": "#333334",
                      "weight": "bold",
                      "action": {
                        "type": "uri",
                        "label": "action",
                        "uri": "${vcard.nfcurl}&cardid=1"
                      }            
                    }
                  ]
                },
                {
                  "type": "box",
                  "layout": "baseline",
                  "contents": [],
                  "backgroundColor": "#d5602d",
                  "height": "3px",
                  "margin": "lg"
                },
                ${vcardPhone}
                ${vcardTel}
                ${vcardLink}
                ${vcardEmail}
                ${vcardAddr}
                {
                  "type": "box",
                  "layout": "baseline",
                  "contents": [],
                  "spacing": "lg",
                  "margin": "sm"
                }                
              ],
              "position": "absolute",
              "offsetTop": "12%",
              "offsetStart": "35%",
              "offsetEnd": "5%"
            }
          ],
          "paddingAll": "0px"
        }
      }
    }
    `;
  return { card };
}

export { genCard };
