import store from '@/store'

function genCard(vcard) {
  let vcardLink;
  if (vcard.url.trim().length > 0) {
    if (vcard.nc_func.indexOf("nourl") !== -1) {
      vcardLink = {
        type: "box",
        layout: "baseline",
        contents: [
          {
            type: "text",
            text: vcard.url,
            color: "#333334",
            size: "sm",
            weight: "bold",
            align: "end",
          },
        ],
        spacing: "lg",
        margin: "xs",
      };
    } else {
      vcardLink = {
        type: "box",
        layout: "baseline",
        contents: [
          {
            type: "text",
            text: vcard.url,
            color: "#333334",
            size: "sm",
            action: {
              type: "uri",
              label: "action",
              uri: vcard.url,
            },
            weight: "bold",
            align: "end",
          },
        ],
        spacing: "lg",
        margin: "xs",
      };
    }
  } else {
    vcardLink = {
      type: "box",
      layout: "baseline",
      contents: [
        {
          type: "text",
          text: " ",
          color: "#333334",
          size: "sm",
          weight: "bold",
          align: "end",
        },
      ],
      spacing: "lg",
      margin: "xs",
    };
  }

  let vcardAddr;
  if (vcard.address.trim().length > 0) {
    vcardAddr = {
      type: "box",
      layout: "vertical",
      contents: [
        {
          type: "text",
          text: vcard.address,
          weight: "bold",
          color: "#333334",
          size: "sm",
          align: "end",
          action: {
            type: "uri",
            label: "action",
            uri: `https://www.google.com.tw/maps/place/${encodeURIComponent(
              vcard.address
            )}`,
          },
          wrap: true,
        },
      ],
      spacing: "lg",
      margin: "xs",
    };
  } else {
    vcardAddr = {
      type: "box",
      layout: "vertical",
      contents: [
        {
          type: "text",
          text: " ",
          weight: "bold",
          color: "#333334",
          size: "sm",
          align: "end",
          wrap: true,
        },
      ],
      spacing: "lg",
      margin: "xs",
    };
  }

  if (vcard.title.trim().length == 0) {
    vcard.title = " ";
  }

  if (vcard.tel.trim().length == 0) {
    vcard.tel = " ";
  }

  let vcardPhone;

  if (vcard.phone.trim().length == 0) {
    vcard.phone = " ";
  }

  let vcardAvatar;
  if (vcard.avatar.trim().length > 0) {
    vcardAvatar = {
      type: "image",
      url: vcard.avatar,
      action: {
        type: "uri",
        label: "action",
        uri: vcard.nfcurl + "&cardid=1",
      },
    };
  } else {
    vcardAvatar = {
      type: "text",
      text: " ",
      size: "xs",
    };
  }

  let vcardEmail;

  if (vcard.email.trim().length > 0) {
    vcardEmail = {
      type: "text",
      text: vcard.email,
      color: "#333334",
      size: "sm",
      weight: "bold",
      action: {
        type: "uri",
        label: "action",
        uri: `mailto:${vcard.email}`,
      },
      align: "end",
    };
  } else {
    vcardEmail = {
      type: "text",
      text: " ",
      color: "#333334",
      size: "sm",
      weight: "bold",
      align: "end",
    };
  }

  let card = {
    altText: "SlashCard電子名片",
    type: "flex",
    contents: {
      type: "bubble",
      size: "giga",
      body: {
        type: "box",
        layout: "vertical",
        contents: [
          {
            type: "image",
            url: "https://"+store.state.domain+"/images/jcibg.png",
            size: "full",
            aspectMode: "fit",
            aspectRatio: "4:3",
            gravity: "top",
          },
          {
            type: "box",
            layout: "vertical",
            contents: [
              vcardAvatar,
              {
                type: "text",
                text: "大同國際\n青年商會",
                wrap: true,
                align: "center",
                margin: "md",
              },
            ],
            position: "absolute",
            offsetTop: "13%",
            offsetStart: "2%",
            width: "21%",
          },
          {
            type: "box",
            layout: "vertical",
            contents: [
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "text",
                    text: "加入好友",
                    align: "center",
                    color: "#ffffff",
                    weight: "bold",
                    margin: "xs",
                  },
                ],
                backgroundColor: "#06c755",
                width: "100%",
                cornerRadius: "5px",
                action: {
                  type: "uri",
                  label: "action",
                  uri: "https://line.naver.jp/ti/p/~" + vcard.line,
                },
              },
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "text",
                    text: "分享名片",
                    align: "center",
                    color: "#ffffff",
                    weight: "bold",
                    margin: "xs",
                  },
                ],
                backgroundColor: "#ffcc5b",
                width: "100%",
                cornerRadius: "5px",
                margin: "md",
              },
            ],
            position: "absolute",
            offsetStart: "2%",
            width: "20%",
            offsetBottom: "8%",
            action: {
              type: "uri",
              label: "action",
              uri: `${process.env.VUE_APP_SEND_URL}/?userid=${vcard.user_id}&cardid=1`,
            },
          },
          {
            type: "box",
            layout: "vertical",
            contents: [
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "text",
                    text: vcard.name,
                    size: "lg",
                    color: "#4EA2D9",
                    weight: "bold",
                    align: "end",
                  },
                ],
              },
              {
                type: "box",
                layout: "baseline",
                contents: [
                  {
                    type: "text",
                    text: vcard.title,
                    color: "#333334",
                    size: "md",
                    weight: "bold",
                    align: "end",
                  },
                ],
                spacing: "lg",
              },
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "separator",
                  },
                ],
                height: "20px",
              },
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "text",
                    text: vcard.company,
                    color: "#4EA2D9",
                    size: "md",
                    weight: "bold",
                    align: "end",
                  },
                ],
                spacing: "lg",
                margin: "xs",
              },
              vcardAddr,
              {
                type: "box",
                layout: "horizontal",
                contents: [
                  {
                    type: "text",
                    text: vcard.tel,
                    align: "end",
                    size: "sm",
                    color: "#333334",
                    weight: "bold",
                  },
                  {
                    type: "text",
                    text: vcard.phone,
                    size: "sm",
                    align: "end",
                    weight: "bold",
                  },
                ],
              },
              vcardLink,
              {
                type: "box",
                layout: "baseline",
                contents: [vcardEmail],
                spacing: "lg",
                margin: "xs",
              },
            ],
            position: "absolute",
            offsetTop: "30%",
            offsetStart: "35%",
            offsetEnd: "5%",
          },
        ],
        paddingAll: "0px",
        action: {
          type: "uri",
          label: "action",
          uri: vcard.nfcurl + "&cardid=1",
        },
      },
    },
  };
  return { card: JSON.stringify(card) };
}

export { genCard };
