import store from '@/store'

function genCard(vcard) {
  let vcardLink;
  if (vcard.url.trim().length > 0) {
    if (vcard.nc_func.indexOf("nourl") !== -1) {
      vcardLink = {
        type: "box",
        layout: "baseline",
        contents: [
          {
            type: "text",
            text: vcard.url,
            color: "#333334",
            size: "sm",
            weight: "bold",
            align: "end",
          },
        ],
        spacing: "lg",
        margin: "xs",
      };
    } else {
      vcardLink = {
        type: "box",
        layout: "baseline",
        contents: [
          {
            type: "text",
            text: vcard.url,
            color: "#333334",
            size: "sm",
            action: {
              type: "uri",
              label: "action",
              uri: vcard.url,
            },
            weight: "bold",
            align: "end",
          },
        ],
        spacing: "lg",
        margin: "xs",
      };
    }
  } else {
    vcardLink = {
      type: "box",
      layout: "baseline",
      contents: [
        {
          type: "text",
          text: " ",
          color: "#333334",
          size: "sm",
          weight: "bold",
          align: "end",
        },
      ],
      spacing: "lg",
      margin: "xs",
    };
  }

  let vcardAddr;
  if (vcard.address.trim().length > 0) {
    vcardAddr = {
      type: "box",
      layout: "vertical",
      contents: [
        {
          type: "text",
          text: vcard.address,
          weight: "bold",
          color: "#333334",
          size: "sm",
          align: "end",
          action: {
            type: "uri",
            label: "action",
            uri: `https://www.google.com.tw/maps/place/${encodeURIComponent(
              vcard.address
            )}`,
          },
          wrap: true,
        },
      ],
      spacing: "lg",
      margin: "xs",
    };
  } else {
    vcardAddr = {
      type: "box",
      layout: "vertical",
      contents: [
        {
          type: "text",
          text: " ",
          weight: "bold",
          color: "#333334",
          size: "sm",
          align: "end",
          wrap: true,
        },
      ],
      spacing: "lg",
      margin: "xs",
    };
  }

  if (vcard.title.trim().length == 0) {
    vcard.title = " ";
  }

  if (vcard.tel.trim().length == 0) {
    vcard.tel = " ";
  }

  let vcardPhone;

  if (vcard.phone.trim().length == 0) {
    vcard.phone = " ";
  }

  let vcardAvatar;
  if (vcard.avatar.trim().length > 0) {
    vcardAvatar = {
      type: "image",
      url: vcard.avatar,
      action: {
        type: "uri",
        label: "action",
        uri: vcard.nfcurl + "&cardid=1",
      },
    };
  } else {
    vcardAvatar = {
      type: "text",
      text: " ",
      size: "xs",
    };
  }

  let vcardEmail;

  if (vcard.email.trim().length > 0) {
    vcardEmail = {
      type: "text",
      text: vcard.email,
      color: "#333334",
      size: "sm",
      weight: "bold",
      action: {
        type: "uri",
        label: "action",
        uri: `mailto:${vcard.email}`,
      },
      align: "end",
    };
  } else {
    vcardEmail = {
      type: "text",
      text: " ",
      color: "#333334",
      size: "sm",
      weight: "bold",
      align: "end",
    };
  }

  let card = {
    altText: "UTel電子名片",
    type: "flex",
    contents: {
      type: "bubble",
      size: "giga",
      body: {
        type: "box",
        layout: "vertical",
        contents: [
          {
            type: "image",
            url: "https://demo.zltest.com.tw/line/bg05.jpg",
            size: "full",
            aspectMode: "cover",
            aspectRatio: "4:3",
            gravity: "top",
          },
          {
            type: "box",
            layout: "vertical",
            contents: [
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "image",
                    url: "https://demo.zltest.com.tw/510/logo.jpg",
                  },
                ],
                offsetStart: "5%",
                width: "17%",
              },
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "box",
                    layout: "vertical",
                    contents: [
                      {
                        type: "text",
                        text: "綜廉廣告科技有限公司",
                        color: "#333334",
                        size: "24px",
                        weight: "bold",
                      },
                    ],
                  },
                  {
                    type: "box",
                    layout: "vertical",
                    contents: [
                      {
                        type: "text",
                        text: "台北市南京東路四段133巷7弄23號",
                        color: "#333334",
                        size: "md",
                        action: {
                          type: "uri",
                          label: "action",
                          uri: "https://goo.gl/maps/GXkbAnx8idesQp1c8",
                        },
                      },
                    ],
                  },
                ],
                position: "absolute",
                offsetStart: "25%",
                width: "65%",
                offsetTop: "10%",
              },
            ],
            width: "100%",
            offsetTop: "7%",
            position: "absolute",
          },
          {
            type: "box",
            layout: "vertical",
            contents: [
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "box",
                    layout: "vertical",
                    contents: [
                      {
                        type: "text",
                        text: "胡怡霖 / 總經理",
                        color: "#ffffff",
                        size: "xl",
                        weight: "bold",
                      },
                    ],
                  },
                  {
                    type: "box",
                    layout: "vertical",
                    contents: [
                      {
                        type: "text",
                        text: "聯絡電話 : 02-6600-8966",
                        color: "#ffffff",
                        size: "md",
                        action: {
                          type: "uri",
                          label: "action",
                          uri: "tel:02-66008966",
                        },
                      },
                    ],
                  },
                ],
                offsetStart: "7%",
                width: "90%",
              },
            ],
            width: "100%",
            offsetTop: "38%",
            position: "absolute",
          },
          {
            type: "box",
            layout: "horizontal",
            contents: [
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "image",
                    url: "https://demo.zltest.com.tw/line/c001.png",
                    size: "50px",
                    action: {
                      type: "uri",
                      label: "action",
                      uri: "tel:02-66008966",
                    },
                  },
                  {
                    type: "text",
                    text: "立即來電",
                    color: "#000000",
                    size: "sm",
                    weight: "bold",
                    align: "center",
                    margin: "sm",
                  },
                ],
              },
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "image",
                    url: "https://demo.zltest.com.tw/line/c002.png",
                    size: "50px",
                    action: {
                      type: "uri",
                      label: "action",
                      uri: "https://www.coolgo.tw",
                    },
                  },
                  {
                    type: "text",
                    text: "前往網站",
                    color: "#000000",
                    size: "sm",
                    weight: "bold",
                    align: "center",
                    margin: "sm",
                  },
                ],
              },
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "image",
                    url: "https://demo.zltest.com.tw/line/c003.png",
                    size: "50px",
                    action: {
                      type: "uri",
                      label: "action",
                      uri: "mailto:510@cityagogo.com.tw",
                    },
                  },
                  {
                    type: "text",
                    text: "發送信箱",
                    color: "#000000",
                    size: "sm",
                    weight: "bold",
                    align: "center",
                    margin: "sm",
                  },
                ],
              },
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "image",
                    url: "https://demo.zltest.com.tw/line/c004.png",
                    size: "50px",
                    action: {
                      type: "uri",
                      label: "action",
                      uri: "https://goo.gl/maps/GXkbAnx8idesQp1c8",
                    },
                  },
                  {
                    type: "text",
                    text: "地址導航",
                    color: "#000000",
                    size: "sm",
                    weight: "bold",
                    align: "center",
                    margin: "sm",
                  },
                ],
              },
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "image",
                    url: "https://demo.zltest.com.tw/line/c005.png",
                    size: "50px",
                    action: {
                      type: "uri",
                      label: "action",
                      uri: "https://line.naver.jp/ti/p/~0973092510",
                    },
                  },
                  {
                    type: "text",
                    text: "加入好友",
                    color: "#000000",
                    size: "sm",
                    weight: "bold",
                    align: "center",
                    margin: "sm",
                  },
                ],
              },
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "image",
                    url: "https://demo.zltest.com.tw/line/c006.png",
                    size: "50px",
                    action: {
                      type: "uri",
                      label: "action",
                      uri: "https://utel.vip/send/?userid=mc623012c9b344d&cardid=1",
                    },
                  },
                  {
                    type: "text",
                    text: "分享名片",
                    color: "#000000",
                    size: "sm",
                    weight: "bold",
                    align: "center",
                    margin: "sm",
                  },
                ],
              },
            ],
            width: "100%",
            offsetTop: "65%",
            position: "absolute",
          },
        ],
        paddingAll: "0px",
      },
    },
  };
  return { card: JSON.stringify(card) };
}

export { genCard };
