import store from '@/store'

function genCard(vcard) {
  let vcardLink;
  if (vcard.url.trim().length > 0) {
    if (vcard.nc_func.indexOf("nourl") !== -1) {
      vcardLink = {
        type: "text",
        text: vcard.url,
        color: "#FFFFFF",
        align: "end",
      };
    } else {
      vcardLink = {
        type: "text",
        text: vcard.url,
        color: "#FFFFFF",
        align: "end",
        action: {
          type: "uri",
          label: "action",
          uri: vcard.url,
        },
      };
    }
  } else {
    vcardLink = {
      type: "text",
      text: " ",
      color: "#FFFFFF",
      align: "end",
    };
  }

  let vcardAddr;
  if (vcard.address.trim().length > 0) {
    vcardAddr = {
      type: "box",
      layout: "vertical",
      contents: [
        {
          type: "text",
          text: vcard.address.substr(0, 3),
          size: "sm",
        },
        {
          type: "text",
          text:
            vcard.address.substr(3).length > 0 ? vcard.address.substr(3) : " ",
          size: "sm",
          wrap: true,
          maxLines: 2,
        },
      ],
      position: "absolute",
      offsetTop: "30%",
      offsetStart: "25%",
      width: "40%",
      height: "60px",
      action: {
        type: "uri",
        label: "action",
        uri: `https://www.google.com.tw/maps/place/${encodeURIComponent(
          vcard.address
        )}`,
      },
    };
  } else {
    vcardAddr = {
      type: "box",
      layout: "vertical",
      contents: [
        {
          type: "text",
          text: " ",
          size: "sm",
        },
      ],
      position: "absolute",
      offsetTop: "30%",
      offsetStart: "25%",
      width: "40%",
      height: "60px",
    };
  }

  if (vcard.title.trim().length == 0) {
    vcard.title = " ";
  }

  let vcardTel;

  if (vcard.tel.trim().length == 0) {
    vcardTel = {
      type: "text",
      text: " ",
      align: "end",
    };
  } else {
    vcardTel = {
      type: "text",
      text: vcard.tel,
      align: "end",
      action: {
        type: "uri",
        label: "action",
        uri: "tel:" + vcard.tel,
      },
    };
  }

  let vcardPhone;

  if (vcard.phone.trim().length == 0) {
    vcardPhone = {
      type: "text",
      text: " ",
      align: "end",
    };
  } else {
    vcardPhone = {
      type: "text",
      text: vcard.phone,
      align: "end",
      action: {
        type: "uri",
        label: "action",
        uri: "tel:" + vcard.phone,
      },
    };
  }

  let vcardAvatar;
  if (vcard.avatar.trim().length > 0) {
    vcardAvatar = {
      type: "image",
      url: vcard.avatar,
      action: {
        type: "uri",
        label: "action",
        uri: vcard.nfcurl + "&cardid=1",
      },
      aspectMode: "cover",
      size: "full",
    };
  } else {
    vcardAvatar = {
      type: "text",
      text: " ",
      size: "xs",
    };
  }

  let vcardEmail;

  if (vcard.email.trim().length > 0) {
    vcardEmail = {
      type: "text",
      text: vcard.email,
      color: "#333334",
      size: "sm",
      weight: "bold",
      action: {
        type: "uri",
        label: "action",
        uri: `mailto:${vcard.email}`,
      },
      align: "end",
    };
  } else {
    vcardEmail = {
      type: "text",
      text: " ",
      color: "#333334",
      size: "sm",
      weight: "bold",
      align: "end",
    };
  }

  let card = {
    altText: "UTel電子名片",
    type: "flex",
    contents: {
      type: "bubble",
      size: "giga",
      body: {
        type: "box",
        layout: "vertical",
        contents: [
          {
            type: "image",
            url: "https://"+store.state.domain+"/images/tpl02_bg.png",
            size: "full",
            aspectMode: "cover",
            aspectRatio: "4:3",
            gravity: "top",
          },
          {
            type: "box",
            layout: "vertical",
            contents: [vcardAvatar],
            position: "absolute",
            offsetTop: "13%",
            offsetStart: "3%",
            width: "75px",
            height: "75px",
            cornerRadius: "100px",
          },
          {
            type: "box",
            layout: "vertical",
            contents: [
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "text",
                    text: "加入好友",
                    align: "center",
                    color: "#ffffff",
                    weight: "bold",
                    margin: "xs",
                    size: "xs",
                  },
                ],
                backgroundColor: "#ffcc5b",
                width: "100%",
                cornerRadius: "5px",
                action: {
                  type: "uri",
                  label: "action",
                  uri: "https://line.naver.jp/ti/p/~" + vcard.line,
                },
                paddingTop: "5px",
                paddingBottom: "5px",
              },
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "text",
                    text: "分享名片",
                    align: "center",
                    color: "#ffffff",
                    weight: "bold",
                    margin: "xs",
                    size: "xs",
                  },
                ],
                backgroundColor: "#ffcc5b",
                width: "100%",
                cornerRadius: "5px",
                margin: "md",
                paddingTop: "5px",
                paddingBottom: "5px",
              },
            ],
            position: "absolute",
            offsetStart: "10%",
            width: "20%",
            offsetBottom: "16%",
            action: {
              type: "uri",
              label: "action",
              uri: `${process.env.VUE_APP_SEND_URL}/?userid=${vcard.user_id}&cardid=1`,
            },
          },
          {
            type: "box",
            layout: "vertical",
            contents: [
              {
                type: "text",
                text: vcard.name,
              },
            ],
            position: "absolute",
            offsetTop: "13%",
            offsetStart: "25%",
            width: "90px",
          },
          {
            type: "box",
            layout: "vertical",
            contents: [
              {
                type: "text",
                text: vcard.title,
              },
            ],
            position: "absolute",
            offsetTop: "13%",
            offsetStart: "51%",
            width: "50%",
          },
          vcardAddr,
          {
            type: "box",
            layout: "vertical",
            contents: [vcardPhone, vcardTel],
            position: "absolute",
            offsetTop: "30%",
            offsetEnd: "5%",
            width: "30%",
          },
          {
            type: "box",
            layout: "vertical",
            contents: [
              {
                type: "text",
                text: vcard.company,
                size: "lg",
                color: "#EFEFEF",
                align: "end",
                weight: "bold",
              },
              vcardLink,
            ],
            position: "absolute",
            offsetTop: "65%",
            offsetEnd: "5%",
            width: "60%",
          },
        ],
        paddingAll: "0px",
        action: {
          type: "uri",
          label: "action",
          uri: vcard.nfcurl + "&cardid=1",
        },
      },
    },
  };
  return { card: JSON.stringify(card) };
}

export { genCard };
